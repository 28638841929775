<template>
    <Header active_menu="1" />
    <div class="flexjc">
        <div>
            <div class="imgbox w100 relative">
                <img src="../assets/img/aboutus.png" alt="">
                <div class="text5_box absolute">
                    <span>{{ $t('关于我们') }}</span>
                    <div class="us">About us</div>
                    <div class="text">
                        {{
                                $t('⽇本上市公司AucfanCo.,Ltd.的中国⼦公司，拥有⽇本最⼤的BtoB批发采购平台NETSEA，5000家供货商和49万家买家在⽹络交易，约160万个优质⽇本产品。我们为中国与⽇本企业客户提供便捷安全的商品流通平台，提供海量优质⽇本产品的同时，为中国客户拓宽⽇本销售渠道，为企业客户的滞销及库存产品提供更多可能。')
                        }}
                    </div>
                </div>
            </div>
            <div class="  info_box">
                <div>
                    <span class="name">{{ $t('公司信息') }}</span>
                    <div class="name1">Company information</div>
                </div>
                <div class="flexjc introduce">
                    <div class="info-text">
                        {{ $t('名称：傲可凡（海南）网络科技有限公司') }} <br>
                        {{ $t('地址：海南省海口市秀英区粤海大道155号海南未来产业园企业服务中心10-20-643') }} <br>
                        {{ $t('成⽴：2022年6⽉') }} <br>
                        {{ $t('法⼈：藤崎 竜成') }} <br>
                        {{ $t('资本⾦：1000万元') }}<br>
                        {{ $t('总公司：Aucfan Co.,Ltd.(日本上市公司）') }} <br>
                    </div>
                </div>
                <div class="COPY">COPYRIGHT(@)2022mo005_19560-{{ $t('企业集团类官网') }}</div>
            </div>


        </div>
    </div>


</template>   

<script>
import Header from '../components/Header2.vue'
import { reactive, toRefs } from "vue";


export default {
    name: 'Home',

    setup() {
        let active = reactive({

        })

        return {
            ...toRefs(active),
        };
    },
    components: {
        Header,
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.info-text {
    margin-top: 85px;
    max-width: 743px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    line-height: 40px;
    color: #666666;
    opacity: 1;
    margin-bottom: 180px;
}

.name1 {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #E5E5E6;
    margin-top: 10px;
}

.name {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
}

.info_box {
    max-width: 70%;
    margin: 0 auto;
    margin: 100px auto;
}

.text {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 27px;
    color: #FFFFFF;
    opacity: 0.8;
}

.us {
    font-size: 48px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 40px;

}

.text5_box {
    top: 200px;
    max-width: 755px;
    left: 294px;
}

.imgbox {
    width: 100%;
    margin: 0 auto;
    height: 500px;
    /* background: url('../assets/img/aboutus.png') no-repeat; */
    color: #fff;
    /* left: 50%;
    transform: translateX(-50%); */

}

.img_us {
    max-width: 100%;
    margin: 0 auto;
}

.content {
    max-width: 70%;
    margin: 0 auto;
    padding: 148px 0;
    box-sizing: border-box;
    flex-direction: column;
}


/* 9.8号添加 */
.imgbox>img{
    width: 1430px;
    height: 500px;
}



@media screen and (max-width:1000px) {
    .imgbox>img{
        /* width: 100px; */
        /* display: none; */
        margin-top: 15vw;
        width: 100vw;
        height: 50vw;
        /* border: 1px solid red; */
    }
    .imgbox{
        height: 300px;
    }
    .text5_box{
        top: 80px;
        left: 20px;
    }
    .us{
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .text{
        font-size: 10px;
        line-height: 15px;
    }
    .info_box{
        margin: 0 15px;
    }
    .name{
        font-size: 12px;
    }
    .name1{
        font-size: 15px;
    }
    .info-text{
        margin-top: 20px;
    }
    .introduce>.info-text{
        font-size: 8px;
        line-height: 10px;
        margin-bottom: 10px;
    }
    .COPY{
        font-size: 8px;
    }
}
</style>
